import {
  createBaseThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';

export const danfossTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: '#303030',
      },
      secondary: {
        main: '#E2000F7F',
      },
      error: {
        main: '#E2000F',
      },
      warning: {
        main: '#FFAA00',
      },
      info: {
        main: '#46B9F3',
      },
      success: {
        main: '#14C510',
      },
      background: {
        default: '#F4F4F4',
        paper: '#FFFFFF',
      },
      banner: {
        info: '#46B9F3',
        error: '#E2000F',
        text: '#F4F4F4',
        link: '#FFFFFF',
      },
      errorBackground: '#E2000F',
      warningBackground: '#FFAA00',
      infoBackground: '#46B9F3',
      navigation: {
        background: '#303030',
        indicator: '#FFAA00',
        color: '#ffffff',
        selectedColor: '#B6000F',
      },
    },
  }),
  defaultPageTheme: 'home',
  fontFamily: 'Helvetica Neue, Helvetica, Roboto, Arial, sans-serif',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({
      colors: ['#D71920', '#D71920'],
      shape: shapes.wave,
    }),
    documentation: genPageTheme({
      colors: ['#D71920', '#D71920'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({
      colors: ['#D71920', '#D71920'],
      shape: shapes.round,
    }),
    service: genPageTheme({
      colors: ['#D71920', '#D71920'],
      shape: shapes.wave,
    }),
    resources: genPageTheme({
      colors: ['#1BBC9B', '#1BBC9B'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#D71920', '#D71920'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#F1C40F', '#F1C40F'],
      shape: shapes.wave,
    }),
    other: genPageTheme({
      colors: ['#D71920', '#D71920'],
      shape: shapes.wave,
    }),
    app: genPageTheme({ colors: ['#D71920', '#D71920'], shape: shapes.wave }),
    apis: genPageTheme({
      colors: ['#E30177', '#E30177'],
      shape: shapes.wave,
    }),
  },
});
