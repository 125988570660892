import {
  HomePageToolkit,
  HomePageCompanyLogo,
  HomePageStarredEntities,
  TemplateBackstageLogoIcon,
  HomePageTopVisited,
  HomePageRecentlyVisited,
} from '@backstage/plugin-home';
import { Content, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { AlsenseBackstageLogo } from './AlsenseBackstageLogo';

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '8px 0',
    borderRadius: '50px',
    margin: 'auto',
  },
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 150,
  },
  path: {
    fill: '#E2000F',
  },
}));

export const HomePage = () => {
  const classes = useStyles();
  const { svg, path, container } = useLogoStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <HomePageCompanyLogo
              className={container}
              logo={<AlsenseBackstageLogo classes={{ svg, path }} />}
            />
            <Grid container item xs={12} alignItems="center" direction="row">
              <HomePageSearchBar
                classes={{ root: classes.searchBar }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit
                  tools={[
                    {
                      url: 'https://backstage.alsenseplatform.com/docs/default/component/alsense-backstage/start/',
                      label: 'Backstage - Getting started',
                      icon: <TemplateBackstageLogoIcon />,
                    },
                    {
                      url: 'https://api-studio.alsenseplatform.com/',
                      label: 'API Studio',
                      icon: <TemplateBackstageLogoIcon />,
                    },
                    {
                      url: 'https://danfoss.visualstudio.com/Alsense',
                      label: 'Azure DevOps',
                      icon: <TemplateBackstageLogoIcon />,
                    },
                    {
                      url: 'https://www.figma.com/files/933408918843941231/team/962367968162085561/Alsense%E2%84%A2-IoT-Platform?fuid=1036914282010218420',
                      label: 'Figma',
                      icon: <TemplateBackstageLogoIcon />,
                    },
                    {
                      url: 'https://rancher.alsenseplatform.com',
                      label: 'Rancher',
                      icon: <TemplateBackstageLogoIcon />,
                    },
                    {
                      url: 'https://grafana.alsenseplatform.com',
                      label: 'Grafana',
                      icon: <TemplateBackstageLogoIcon />,
                    },
                    {
                      url: 'https://app.lokalise.com/projects',
                      label: 'Lokalise',
                      icon: <TemplateBackstageLogoIcon />,
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageTopVisited />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageRecentlyVisited />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
