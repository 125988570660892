import { ConfigApi, DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';

import { Manifest, DockerApi, TagsResponse } from './types';

export interface DockerClientOptions {
  fetchApi: FetchApi;
  discoveryApi: DiscoveryApi;
  configApi: ConfigApi;
}

export class DockerClient implements DockerApi {
  constructor(private options: DockerClientOptions) {}

  async getRepositories(
    url: string,
    pageNumber: number,
    pageSize: number,
  ): Promise<TagsResponse> {
    const baseUrl = await this.options.discoveryApi.getBaseUrl('');

    const targetUrl = `${baseUrl}proxy${url}`;

    return new Promise((resolve, reject) => {
      this.options.fetchApi
        .fetch(`${targetUrl}?page=${pageNumber}&page_size=${pageSize}`)
        .then(res => res.json())
        .then(res => {
          if ('errinfo' in res) {
            return reject({
              name: 'Error',
              message: `Could not find namespace ${res.errinfo.namespace} or repository ${res.errinfo.repository}`,
            });
          }
          return resolve(res);
        });
    });
  }

  async getACRRepositories(url: string): Promise<TagsResponse> {
    const baseUrl = await this.options.discoveryApi.getBaseUrl('');

    const targetUrl = `${baseUrl}proxy${url}`;

    return new Promise((resolve, reject) => {
      this.options.fetchApi
        .fetch(`${targetUrl}`)
        .then(res => res.json())
        .then(res => {
          if ('errors' in res) {
            return reject({
              name: 'Error',
              message: `Error code: ${res.errors[0].code} | Message: ${res.errors[0].message}`,
            });
          }
          return resolve({
            count: res.manifests.length,
            next: undefined,
            previous: undefined,
            results: res.manifests.map((manifest: Manifest) => {
              if (!manifest.tags) {
                manifest.tags = ['no tag']
              }
              return {
                name: manifest.tags[0],
                tag_status: manifest.changeableAttributes.readEnabled
                  ? 'active'
                  : 'inactive',
                images: [
                  {
                    architecture: manifest.architecture,
                    digest: manifest.digest,
                    os: manifest.os,
                    size: manifest.imageSize,
                    status: manifest.changeableAttributes.quarantineState,
                    last_pulled: manifest.lastUpdateTime,
                    last_pushed: manifest.lastUpdateTime,
                  },
                ],
                last_updated: manifest.lastUpdateTime,
                tag_last_pulled: manifest.lastUpdateTime,
                tag_last_pushed: manifest.lastUpdateTime,
                media_type: manifest.mediaType,
                content_type: manifest.configMediaType,
                digest: manifest.digest,
              };
            }),
          });
        });
    });
  }
}
