import { ChangelogProps, ChangelogAction, EntityChangelogProps } from '@rsc-labs/backstage-changelog-plugin';
import { StatusError, StatusOK, StatusPending, StatusRunning, StatusWarning } from '@backstage/core-components';

export const parseChangelog: EntityChangelogProps['parser'] = (content: string): ChangelogProps[] => {

    const versionBlocks = content.split(/(?:\n## |\n# )/).slice(0); // Split by version blocks
    return versionBlocks.map((block) => {
      const lines = block.split('\n');
      let versionLine = lines[0];
      versionLine = versionLine.replace(/^##? /, ''); 
      const versionNumber = versionLine.split(' ')[0];
      const actions: ChangelogAction[] = [];
      let actionName = '';
      let counter = 0;
      let actionContent = '';
      for (let i = 1; i < lines.length; i++) {
        const line = lines[i];
        if (line.startsWith('### ')) {
          if (actionName) {
            actions.push({ name: actionName, counter, content: actionContent.trim() , icon: getActionIcon(actionName) });
          }
          actionName = line.slice(4);
          counter = 0;
          actionContent = '';
        } else if (line.startsWith('* ')) {
          counter++;
          actionContent += `${line.slice(2)  }\n`;
        }
      }
      if (actionName) {
        actions.push({ name: actionName, counter, content: actionContent.trim() , icon: getActionIcon(actionName) });
      }
      return {
        versionNumber,
        actions,
        versionContent: block.split('\n').slice(1).join('\n'),
      };
    });
  };

  function getActionIcon(actionName: string) {
    switch (actionName) {
      case 'Features': return StatusOK;
      case 'Bug Fixes': return StatusError;
      case 'Performance Improvements': return StatusRunning;
      case 'Reverts': return StatusWarning;
      case 'Documentation': return StatusPending;
      case 'Styles': return StatusPending;
      case 'Miscellaneous Chores': return StatusPending;
      case 'Code Refactoring': return StatusPending;
      case 'Tests': return StatusPending;
      case 'Build System': return StatusPending;
      case 'Continuous Integration': return StatusPending;
      default: return undefined;
    }
  }





