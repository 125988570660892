import React from 'react';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { HomePageIFrameCard } from '@roadiehq/backstage-plugin-iframe';
import { Content, Page} from '@backstage/core-components';

export const OpenMetaDataPage = () => {
  const configApi = useApi(configApiRef);
  const organizationName =
    configApi.getOptionalString('organization.name') ?? 'Backstage';

const iframePropsOpenMeta = {
      title: `${organizationName} Data Catalog`,
      src: 'https://metadata.alsenseplatform.com',
      height: '1150px',
    };

  return (
    <Page themeId="home">
      <Content>
        <HomePageIFrameCard {...iframePropsOpenMeta} />
    </Content>
    </Page>
  );
};
