import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const TestkubeIcon = () => {
  return (
    <SvgIcon x="0px" y="0px" viewBox="0 0 48 48">
      <path
        d="M33.484 15.67 18.45.588a2.005 2.005 0 0 0-2.827 0L.586 15.669c-.373.374-.584.88-.586 1.41v7.852c.002.53.213 1.036.586 1.41l15.036 15.08a2.004 2.004 0 0 0 2.827 0l15.035-15.08c.374-.374.585-.88.587-1.41V17.08a2.004 2.004 0 0 0-.587-1.41Zm-1.25 8.255L29.33 21.01l2.903-2.911v5.825Zm-4.158-4.172L17.923 9.567V2.576l13.638 13.68-3.485 3.497ZM7.25 21.009l9.786-9.816 9.787 9.816-9.787 9.818-9.786-9.818Zm8.899-18.433v6.99L5.994 19.754 2.51 16.255l13.639-13.68Zm.887 37.758v-6.992l11.041-11.074 3.485 3.505-14.526 14.561Z"
        fill="white"
      />
    </SvgIcon>
  );
};
