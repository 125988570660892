import {
  createPlugin,
  createRoutableExtension,
  createComponentExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const jfrogArtifactoryPlugin = createPlugin({
  id: 'jfrog-artifactory',
  routes: {
    root: rootRouteRef,
  },
});

export const JFrogLibArtifactCard = jfrogArtifactoryPlugin.provide(
  createComponentExtension({
    name: 'LibArtifactCard',
    component: {
      lazy: () =>
        import('./components/LibArtifactCard').then(m => m.LibArtifactCard),
    },
  }),
);

export const JFrogArtifactoryLibsPage = jfrogArtifactoryPlugin.provide(
  createRoutableExtension({
    name: 'JfrogArtifactoryLibsPage',
    component: () =>
      import('./components/LibArtifactCard').then(m => m.LibArtifactCard),
    mountPoint: rootRouteRef,
  }),
);

export const JFrogLibVerPage = jfrogArtifactoryPlugin.provide(
  createComponentExtension({
    name: 'LibVerPage',
    component: {
      lazy: () =>
        import('./components/LibverPage/LibverPage').then(m => m.LibverPage),
    },
  }),
);

export const JFrogLibVerPageContent = jfrogArtifactoryPlugin.provide(
  createComponentExtension({
    name: 'LibVerPageContent',
    component: {
      lazy: () =>
        import('./components/LibverPage/LibverPage').then(
          m => m.libverPageContent,
        ),
    },
  }),
);
