/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, { ReactNode, useMemo, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import {
  EntityApiDefinitionCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
  EntityAboutCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSubcomponentsCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  isComponentType,
  isKind,
  hasCatalogProcessingErrors,
  isOrphan,
  hasRelationWarnings,
  EntityRelationWarning,
} from '@backstage/plugin-catalog';
import {
  isGithubActionsAvailable,
  EntityGithubActionsContent,
} from '@backstage-community/plugin-github-actions';
import {
  EntityUserProfileCard,
  EntityGroupProfileCard,
  EntityMembersListCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { EmptyState } from '@backstage/core-components';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
import {
  EntityAzurePipelinesContent,
  EntityAzurePullRequestsContent,
  EntityAzureGitTagsContent,
  EntityAzureReadmeCard,
  isAzureDevOpsAvailable,
} from '@backstage-community/plugin-azure-devops';
// import { EntityTodoContent } from '@backstage/plugin-todo';
import {
  EntityGrafanaAlertsCard,
  EntityOverviewDashboardViewer,
} from '@backstage-community/plugin-grafana';
import { EntityKubernetesContent } from '@backstage/plugin-kubernetes';
import {
  EntityArgoCDOverviewCard,
  isArgocdAvailable,
} from '@roadiehq/backstage-plugin-argo-cd';
import { EntityBadgesDialog } from '@backstage-community/plugin-badges';
import BadgeIcon from '@material-ui/icons/CallToAction';
import {
  EntityAdrContent,
  isAdrAvailable,
} from '@backstage-community/plugin-adr';
import { EntityLinguistCard } from '@backstage-community/plugin-linguist';
import {
  EntityApiDocsSpectralLinterContent,
  isApiDocsSpectralLinterAvailable,
} from '@dweber019/backstage-plugin-api-docs-spectral-linter';
import { DockerTagsTableWidget } from '@internal/plugin-backstage-docker-plugin';
import { EntityChangelogCard } from '@rsc-labs/backstage-changelog-plugin';
import { parseChangelog } from '../../lib/changelogParsers';
import {
  EntityEndOfLifeCard,
  isEndOfLifeAvailable,
} from '@dweber019/backstage-plugin-endoflife';
import { EntitySonarQubeCard } from '@backstage-community/plugin-sonarqube';
import { EntityDependencytrackSummaryCard } from '@internal/backstage-plugin-dependency-track';
import { EntityTechInsightsScorecardCard } from '@internal/backstage-plugin-tech-insights';
import { EntitySentryCard } from '@backstage-community/plugin-sentry';
import { JFrogLibArtifactCard } from '@internal/backstage-plugin-jfrog-artifactory';
import { EntityAllureReportContent } from '@backstage-community/plugin-allure';
import {
  isInfraWalletAvailable,
} from '@electrolux-oss/plugin-infrawallet';
import { ExtendedEntityInfraWalletCard } from '../costs/EntityInfraWalletCard';

const EntityLayoutWrapper = (props: { children?: ReactNode }) => {
  const [badgesDialogOpen, setBadgesDialogOpen] = useState(false);

  const extraMenuItems = useMemo(() => {
    return [
      {
        title: 'Badges',
        Icon: BadgeIcon,
        onClick: () => setBadgesDialogOpen(true),
      },
    ];
  }, []);

  return (
    <>
      <EntityLayout UNSTABLE_extraContextMenuItems={extraMenuItems}>
        {props.children}
      </EntityLayout>
      <EntityBadgesDialog
        open={badgesDialogOpen}
        onClose={() => setBadgesDialogOpen(false)}
      />
    </>
  );
};

const cicdContent = (
  // This is an example of how you can implement your company's logic in entity page.
  // You can for example enforce that all components of type 'service' should use GitHubActions
  <EntitySwitch>
    <EntitySwitch.Case if={isGithubActionsAvailable}>
      <EntityGithubActionsContent />
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isAzureDevOpsAvailable}>
      <EntityAzurePullRequestsContent defaultLimit={25} />
      <EntityAzurePipelinesContent defaultLimit={25} />
    </EntitySwitch.Case>

    <EntitySwitch.Case>
      <EmptyState
        title="No CI/CD available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);

const techInsightsContent = (
  <Grid container spacing={1} alignItems="stretch">
    <Grid item xs={4}>
      <EntityTechInsightsScorecardCard
        title="Maturity"
        description="Ensuring the service is production ready"
        checksId={[
          'grafanaCheck',
          'argoCDCheck',
          'k8sCheck',
          'sentryCheck',
          'depTrackCheck',
          'sonarQubeCheck',
          'techDocsCheck',
          'groupOwnerCheck',
        ]}
      />
    </Grid>
    <Grid item xs={4}>
      <EntityTechInsightsScorecardCard
        title="Quality"
        description="Ensuring high quality of the code"
        checksId={[
          'CoverageCheck',
          'SonarBugsCheck',
          'CodeSmellCheck',
          'CodeDuplicationCheck',
          'ComplexityCheck',
          'CognitiveLoadCheck',
          'BugCountCheck',
        ]}
      />
    </Grid>
    <Grid item xs={4}>
      <EntityTechInsightsScorecardCard
        title="Security"
        description="Ensuring security of the service"
        checksId={[
          'RiskScoreCheck',
          'PolicyViolationCheck',
          'SonarSecurityCheck',
        ]}
      />
    </Grid>
    <Grid item xs={4}>
      <EntityTechInsightsScorecardCard
        title="Operations"
        description="Ensuring smooth operations"
        checksId={[]}
      />
    </Grid>
  </Grid>
);

const finopsContent = (
  <EntitySwitch>
    <EntitySwitch.Case if={isInfraWalletAvailable}>
      <Grid item md={12}>
        <ExtendedEntityInfraWalletCard />
      </Grid>
    </EntitySwitch.Case>
  </EntitySwitch>
);

const dockerTagsContent = (
  <DockerTagsTableWidget
    heading="Container Tags"
    columns={['name', 'status', 'architecture', 'updated_at']}
  />
);

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasRelationWarnings}>
        <Grid item xs={12}>
          <EntityRelationWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

const overviewContent = (
  <Grid container spacing={1} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>
    <EntitySwitch>
      <EntitySwitch.Case if={e => Boolean(isArgocdAvailable(e))}>
        <Grid item sm={6} md={6}>
          <EntityArgoCDOverviewCard />
        </Grid>
        <Grid item md={6} xs={6}>
          <EntityLinguistCard />
        </Grid>
      </EntitySwitch.Case>
      <EntitySwitch.Case if={e => !Boolean(isArgocdAvailable(e))}>
        <Grid item md={12} xs={12}>
          <EntityLinguistCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <Grid item md={6}>
      <EntitySonarQubeCard variant="gridItem" />
    </Grid>
    <Grid item md={6}>
      <EntityDependencytrackSummaryCard />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityChangelogCard parser={parseChangelog} />
    </Grid>
    <EntitySwitch>
      <EntitySwitch.Case if={isAzureDevOpsAvailable}>
        <Grid item md={6}>
          <EntityAzureReadmeCard maxHeight={350} />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <Grid item md={6} xs={12}>
      <EntityLinksCard />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityHasSubcomponentsCard variant="gridItem" />
    </Grid>
  </Grid>
);

const resourceOverviewContent = (
  <Grid container spacing={1} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>
    <EntitySwitch>
      <EntitySwitch.Case if={e => Boolean(isArgocdAvailable(e))}>
        <Grid item sm={6} md={6}>
          <EntityArgoCDOverviewCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isAzureDevOpsAvailable}>
        <Grid item md={6}>
          <EntityAzureReadmeCard maxHeight={350} />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <Grid item md={6} xs={12}>
      <EntityLinksCard />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityHasSubcomponentsCard variant="gridItem" />
    </Grid>
  </Grid>
);

const businessServiceOverviewContent = (
  <Grid container spacing={1} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>
    <EntitySwitch>
      <EntitySwitch.Case if={e => Boolean(isArgocdAvailable(e))}>
        <Grid item sm={6} md={6}>
          <EntityArgoCDOverviewCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isAzureDevOpsAvailable}>
        <Grid item md={6}>
          <EntityAzureReadmeCard maxHeight={350} />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <Grid item md={6} xs={12}>
      <EntityLinksCard />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityHasSubcomponentsCard variant="gridItem" />
    </Grid>
  </Grid>
);

const monitoringContent = (
  <Grid container spacing={1} alignItems="stretch">
    <Grid item md={12} style={{ minHeight: '600px' }}>
      <EntityOverviewDashboardViewer />
    </Grid>
    <Grid item xs={6} sm={6} md={6}>
      <EntitySentryCard statsFor="14d" />
    </Grid>
    <Grid item md={6}>
      <EntityGrafanaAlertsCard />
    </Grid>
    <Grid item md={12}>
      <EntityKubernetesContent refreshIntervalMs={60000} />
    </Grid>
  </Grid>
);

const artifactsContent = (
  <Grid container spacing={1} alignItems="stretch">
    <Grid item md={6}>
      <JFrogLibArtifactCard />
    </Grid>
    <Grid item md={6}>
      {dockerTagsContent}
    </Grid>
    <Grid item md={12}>
      <EntityAzureGitTagsContent />
    </Grid>
  </Grid>
);
const serviceEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/monitoring" title="Monitoring">
      {monitoringContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/score" title="Tech-Insights">
      {techInsightsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/costs" title="Costs">
      {finopsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/allure" title="Tests Report">
      <EntityAllureReportContent />
    </EntityLayout.Route>

    <EntityLayout.Route
      if={isAzureDevOpsAvailable}
      path="/git-tags"
      title="Artifacts & Tags"
    >
      {artifactsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={1} alignItems="stretch">
        <Grid item md={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={1} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      <EntityTechdocsContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/eol" title="Support">
      <EntitySwitch>
        <EntitySwitch.Case if={isEndOfLifeAvailable}>
          <Grid item md={6}>
            <EntityEndOfLifeCard />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
    </EntityLayout.Route>

    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const toolEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/monitoring" title="Monitoring">
      {monitoringContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/costs" title="Costs">
      {finopsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={1} alignItems="stretch">
        <Grid item md={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      <EntityTechdocsContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/eol" title="Support">
      <EntitySwitch>
        <EntitySwitch.Case if={isEndOfLifeAvailable}>
          <Grid item md={6}>
            <EntityEndOfLifeCard />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
    </EntityLayout.Route>

    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const websiteEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/monitoring" title="Monitoring">
      {monitoringContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/score" title="Tech-Insights">
      {techInsightsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/costs" title="Costs">
      {finopsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/allure" title="Tests Report">
      <EntityAllureReportContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={1} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      <EntityTechdocsContent />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const libraryEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>

    <EntityLayout.Route
      if={isAzureDevOpsAvailable}
      path="/git-tags"
      title="Artifacts & Tags"
    >
      <Grid container spacing={1} alignItems="stretch">
        <Grid item md={6}>
          <JFrogLibArtifactCard />
        </Grid>
        <Grid item md={6}>
          <EntityAzureGitTagsContent />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={1} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      <EntityTechdocsContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/eol" title="Support">
      <EntitySwitch>
        <EntitySwitch.Case if={isEndOfLifeAvailable}>
          <Grid item md={6}>
            <EntityEndOfLifeCard />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
    </EntityLayout.Route>

    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const businessServiceEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {businessServiceOverviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/monitoring" title="Monitoring">
      {monitoringContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/costs" title="Costs">
      {finopsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={1} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      <EntityTechdocsContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/eol" title="Support">
      <EntitySwitch>
        <EntitySwitch.Case if={isEndOfLifeAvailable}>
          <Grid item md={6}>
            <EntityEndOfLifeCard />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
    </EntityLayout.Route>
  </EntityLayout>
);

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

const defaultEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/monitoring" title="Monitoring">
      {monitoringContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      <EntityTechdocsContent />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {serviceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {websiteEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('tool')}>
      {toolEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('library')}>
      {libraryEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('businessservice')}>
      {businessServiceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

const resourcePage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {resourceOverviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/monitoring" title="Monitoring">
      {monitoringContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/costs" title="Costs">
      {finopsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={1} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      <EntityTechdocsContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/eol" title="Support">
      <EntitySwitch>
        <EntitySwitch.Case if={isEndOfLifeAvailable}>
          <Grid item md={6}>
            <EntityEndOfLifeCard />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
    </EntityLayout.Route>
  </EntityLayout>
);

const apiPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={1}>
        <Grid item md={6}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid container item md={12}>
          <Grid item md={6}>
            <EntityProvidingComponentsCard />
          </Grid>
          <Grid item md={6}>
            <EntityConsumingComponentsCard />
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route
      if={isApiDocsSpectralLinterAvailable}
      path="/linter"
      title="Linter"
    >
      <EntityApiDocsSpectralLinterContent />
    </EntityLayout.Route>
  </EntityLayout>
);

const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={1}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const groupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={1}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityMembersListCard />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityLinksCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const systemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={1} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      <EntityTechdocsContent />
    </EntityLayout.Route>

    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent />
    </EntityLayout.Route>
  </EntityLayout>
);

const domainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={1} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityCatalogGraphCard
            variant="gridItem"
            direction={Direction.TOP_BOTTOM}
            mergeRelations={false}
            title="Domain Diagram"
            height={300}
            unidirectional
          />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      <EntityTechdocsContent />
    </EntityLayout.Route>

    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent />
    </EntityLayout.Route>
  </EntityLayout>
);

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('resource')} children={resourcePage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
