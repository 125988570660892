import {
  DomainExplorerContent,
  ExploreLayout,
  GroupsExplorerContent,
  CatalogKindExploreContent
} from '@backstage-community/plugin-explore';
import {
  EntityRelationsGraph,
  Direction,
} from '@backstage/plugin-catalog-graph';
import React from 'react';
import {
  CompoundEntityRef,
  RELATION_OWNER_OF,
} from '@backstage/catalog-model';
import { makeStyles } from '@material-ui/core/styles';

const ownershipGroups: CompoundEntityRef[] = [
  { kind: 'Group', namespace: 'default', name: 'platform' },
  { kind: 'Group', namespace: 'default', name: 'portal' },
  { kind: 'Group', namespace: 'default', name: 'analytics' },
  { kind: 'Group', namespace: 'default', name: 'ops-excellence' },
  { kind: 'Group', namespace: 'default', name: 'swt' },
  { kind: 'Group', namespace: 'default', name: 'alsense' },
];

const useStyles = makeStyles({
  card: () => ({
    display: "flex",
    flexDirection: "column",
    maxHeight: 800,
    minHeight: 600
  }),
  graph: {
    flex: 1,
    minHeight: 0
  }
});

export const ExplorePage = () => {
  return (
    <ExploreLayout
      title="Explore the Alsense Ecosystem"
      subtitle="Browse our ecosystem"
    >
      <ExploreLayout.Route path="domains" title="Domains">
        <DomainExplorerContent />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="systems" title="Systems">
        <CatalogKindExploreContent kind="system" />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="groups" title="Groups">
        <GroupsExplorerContent />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="ownership-graph" title="Ownership">
        <EntityRelationsGraph
          rootEntityNames={ownershipGroups}
          relations={[RELATION_OWNER_OF]}
          direction={Direction.TOP_BOTTOM}
          className={useStyles().card}
        />
      </ExploreLayout.Route>
    </ExploreLayout>
  );
};

export const explorePage = <ExplorePage />;
