import { TestkubeApi } from './TestkubeApi';
import {
  Labels,
  States,
  Test,
  TestExecution,
  TestExecutions,
  TestList,
  TestMetrics,
  TestSuite,
  TestSuiteList,
} from './types';
import { CompoundEntityRef } from '@backstage/catalog-model';
import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';

export class TestkubeClient implements TestkubeApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }
  getTest(testId: string): Promise<Test> {
    return this.api(`/tests/${testId}`);
  }

  getTests(
    pageSize: number,
    page: number,
    textSearch?: string,
    selector?: string,
    status?: States,
  ): Promise<TestList> {
    let url = `/test-with-executions?pageSize=${pageSize}&page=${page}`;
    if (textSearch) {
      url += `&textSearch=${textSearch}`;
    }
    if (selector) {
      url += `&selector=${selector}`;
    }
    if (status) {
      url += `&status=${status}`;
    }
    return this.api(url);
  }

  getTestsForEntity(entity: CompoundEntityRef): Promise<TestList> {
    throw new Error(`Method not implemented. For Entity: ${entity}`);
  }

  runTest(testId: string, namespace: string): Promise<TestExecution> {
    return this.api(`/tests/${testId}/executions`, {
      method: 'POST',
      body: JSON.stringify({ namespace, runningContext: { type: 'user-ui' } }),
    });
  }

  getLabels(): Promise<Labels> {
    return this.api('/labels');
  }

  getTestExecutions(
    testId: string,
    last: number,
    pageSize: number,
    textSearch?: string,
    status?: States,
  ): Promise<TestExecutions> {
    let url = `/tests/${testId}/executions?last=${last}&pageSize=${pageSize}`;

    if (textSearch) {
      url += `&textSearch=${textSearch}`;
    }
    if (status) {
      url += `&status=${status}`;
    }

    return this.api(url);
  }

  getTestMetrics(
    testId: string,
    last: number,
    limit: number,
  ): Promise<TestMetrics> {
    return this.api(`/tests/${testId}/metrics?last=${last}&limit=${limit}`);
  }
  getExecution(executionId: string): Promise<TestExecution> {
    return this.api(`/executions/${executionId}`);
  }

  abortExecution: (testId: string, executionId: string) => Promise<any> = (
    testId: string,
    executionId: string,
  ) => {
    return this.api(`/tests/${testId}/executions/${executionId}`, {
      method: 'PATCH',
    });
  };

  getTestSuite(testSuiteId: string): Promise<TestSuite> {
    return this.api(`/test-suites/${testSuiteId}`);
  }

  getTestSuites(
    testSuiteId: string,
    last: number,
    pageSize: number,
  ): Promise<TestSuiteList> {
    return this.api(
      `/test-suite-executions?id=${testSuiteId}&last=${last}&pageSize=${pageSize}`,
    );
  }

  getTestSuiteMetrics(
    testSuiteId: string,
    last: number,
    limit: number,
  ): Promise<TestMetrics> {
    return this.api(
      `/test-suites/${testSuiteId}/metrics?last=${last}&limit=${limit}`,
    );
  }
  getTestSuiteExecutions(
    pageSize: number,
    page: number,
    textSearch?: string,
    selector?: string,
    status?: States,
  ): Promise<TestSuiteList> {
    let url = `/test-suite-with-executions?pageSize=${pageSize}&page=${page}`;

    if (textSearch) {
      url += `&textSearch=${textSearch}`;
    }
    if (selector) {
      url += `&selector=${selector}`;
    }
    if (status) {
      url += `&status=${status}`;
    }

    return this.api(url);
  }

  private async api<T>(path: string, init?: RequestInit): Promise<T> {
    const url = await this.discoveryApi.getBaseUrl('proxy');
    const { token } = await this.identityApi.getCredentials();

    const headers: HeadersInit = new Headers(init?.headers);
    if (!headers.has('content-type'))
      headers.set('content-type', 'application/json');
    if (token && !headers.has('authorization')) {
      headers.set('authorization', `Bearer ${token}`);
    }

    const request = new Request(`${url}/testkube${path}`, {
      ...init,
      headers,
    });

    return fetch(request).then(async response => {
      if (!response.ok) {
        throw await ResponseError.fromResponse(response);
      }
      return response.json() as Promise<T>;
    });
  }
}
