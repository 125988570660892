import { createApiRef } from '@backstage/core-plugin-api';

type Status = 'active' | 'inactive';

type QuarantineDetails = {
  state: string;
  link: string;
  scanner: string;
  result: {
    version: string;
    summary: Array<{
      severity: string;
      count: number;
    }>;
  };
};

type ChangeableAttributes = {
  deleteEnabled: boolean;
  writeEnabled: boolean;
  readEnabled: boolean;
  listEnabled: boolean;
  quarantineDetails: string | QuarantineDetails;
  quarantineState: string;
};

export type Manifest = {
  digest: string;
  imageSize: number;
  createdTime: string;
  lastUpdateTime: string;
  architecture: string;
  os: string;
  mediaType: string;
  configMediaType: string;
  tags: string[];
  changeableAttributes: ChangeableAttributes;
};

export type Image = {
  architecture: string;
  features: string;
  variant?: string;
  digest: string;
  os: string;
  os_features: string;
  os_version?: string;
  size: number;
  status: Status;
  last_pulled: string;
  last_pushed: string;
};

export type Repository = {
  creator: number;
  id: number;
  images: Partial<Image>[];
  last_updated: string;
  last_updater: number;
  last_updater_username: string;
  name: string;
  repository: number;
  full_size: number;
  v2: boolean;
  tag_status: string;
  tag_last_pulled: string;
  tag_last_pushed: string;
  media_type: string;
  content_type: string;
  digest: string;
};

export type TagsResponse = {
  count: number;
  next?: string;
  previous?: string;
  results: Partial<Repository>[];
};

export interface DockerApi {
  getACRRepositories(url: string): Promise<TagsResponse>;
  getRepositories(
    url: string,
    pageNumber: number,
    pageSize: number,
  ): Promise<TagsResponse>;
}

/**
 * ApiRef for the DockerApi.
 *
 * @public
 */
export const dockerApiRef = createApiRef<DockerApi>({
  id: 'plugin.docker.api',
});
