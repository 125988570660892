import { createApiRef } from '@backstage/core-plugin-api';

import { CompoundEntityRef } from '@backstage/catalog-model';
import {
  TestMetrics,
  Labels,
  States,
  Test,
  TestExecutions,
  TestList,
  TestExecution,
  TestSuiteList,
  TestSuite,
} from './types';

/**
 * {@link @backstage/core-plugin-api#ApiRef} for the {@link TestkubeApi}
 *
 * @public
 */
export const testkubeApiRef = createApiRef<TestkubeApi>({
  id: 'plugin.testkube.service',
});

/**
 * API client interface for the Testkube plugin
 *
 * @public
 */
export interface TestkubeApi {
  // https://testkube.dev-dis-cooling.net/results/v1/tests/graphing-api-test
  getTest: (testId: string) => Promise<Test>;
  // https://testkube.dev-dis-cooling.net/results/v1/test-with-executions?textSearch=api&pageSize=20&page=0&selector=executor%3Dpostman-executor&status=passed
  getTests: (
    pageSize: number,
    page: number,
    textSearch?: string,
    selector?: string,
    status?: States,
  ) => Promise<TestList>;
  getTestsForEntity: (entity: CompoundEntityRef) => Promise<TestList>;
  // https://testkube.dev-dis-cooling.net/results/v1/labels
  getLabels: () => Promise<Labels>;
  // https://testkube.dev-dis-cooling.net/results/v1/tests/gcm-generic-suction-group-sm800-uat-test/executions?last=7&pageSize=1000&textSearch=gcm&status=failed
  getTestExecutions: (
    testId: string,
    last: number,
    pageSize: number,
    textSearch?: string,
    status?: States,
  ) => Promise<TestExecutions>;
  // https://testkube.dev-dis-cooling.net/results/v1/tests/graphing-api-test/metrics?last=7&limit=1000
  getTestMetrics: (
    testId: string,
    last: number,
    limit: number,
  ) => Promise<TestMetrics>;
  // https://testkube.dev-dis-cooling.net/results/v1/executions/663a24309942e93b8a94024a
  getExecution: (executionId: string) => Promise<TestExecution>;
  // https://testkube.dev-dis-cooling.net/results/v1/tests/e2e-test.dev.users.users-favourites/executions
  // Body: {"namespace":"testkube","runningContext":{"type":"user-ui"}}
  runTest: (testId: string, namespace: string) => Promise<TestExecution>;
  abortExecution: (testId: string, executionId: string) => Promise<any>;


  // https://testkube.dev-dis-cooling.net/results/v1/test-suites/test-suite.uat.reporting
  getTestSuite: (testSuiteId: string) => Promise<TestSuite>;
  // https://testkube.dev-dis-cooling.net/results/v1/test-suite-executions?id=test-suite.uat.reporting&last=7&pageSize=1000
  getTestSuites: (testSuiteId: string, last: number, pageSize: number) => Promise<TestSuiteList>;
  // https://testkube.dev-dis-cooling.net/results/v1/test-suites/test-suite.uat.reporting/metrics?last=7&limit=1000
  getTestSuiteMetrics: (testSuiteId: string, last: number, limit: number) => Promise<TestMetrics>;
  // https://testkube.dev-dis-cooling.net/results/v1/test-suite-with-executions?pageSize=20&page=0&textSearch=api&selector=executor%3Dpostman-executor&status=passed
  getTestSuiteExecutions: (
    pageSize: number,
    page: number,
    textSearch?: string,
    selector?: string,
    status?: States,
  ) => Promise<TestSuiteList>;
}
