import {
  RadarRing,
  RadarQuadrant,
  RadarEntry,
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage-community/plugin-tech-radar';
import { Config } from '@backstage/config';
import { IdentityApi } from '@backstage/core-plugin-api';

export class TechRadarClient implements TechRadarApi {
  backendBaseUrl: string;
  identityApi: IdentityApi;

  constructor(config: Config, identityApi: IdentityApi) {
    this.backendBaseUrl = config.get('backend.baseUrl');
    this.identityApi = identityApi;
  }

  async load(id: string | undefined): Promise<TechRadarLoaderResponse> {
    const rings = new Array<RadarRing>();

    const quadrants = new Array<RadarQuadrant>();

    const entries = new Array<RadarEntry>();

    let radarObj: TechRadarLoaderResponse = {
      rings,
      quadrants,
      entries,
    };
    const url = `${this.backendBaseUrl}/api/techradar-git/${id}`;
    const { token } = await this.identityApi.getCredentials();
    await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => response.text())
      .then(responseString => {
        const responseJson = JSON.parse(responseString, this.reviver);
        responseJson.entries.forEach((element: RadarEntry) => {
          entries.push(element);
        });
        responseJson.quadrants.forEach((element: RadarQuadrant) => {
          quadrants.push(element);
        });
        responseJson.rings.forEach((element: RadarRing) => {
          rings.push(element);
        });
        radarObj = {
          quadrants,
          rings,
          entries,
        };
        return radarObj;
      })
      .catch();
    return radarObj;
  }

  reviver(key: string, value: string) {
    if (key === 'date') {
      return new Date(value);
    }

    return value;
  }
}
