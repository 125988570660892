import {
  createPlugin,
  createApiFactory,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { testkubeApiRef, TestkubeClient } from './api';

export const testkubePlugin = createPlugin({
  id: 'testkube',
  apis: [
    createApiFactory({
      api: testkubeApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) => new TestkubeClient({ discoveryApi, identityApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const TestkubePage = testkubePlugin.provide(
  createRoutableExtension({
    name: 'TestkubePage',
    component: () =>
      import('./components/TestkubePage').then(m => m.TestkubePage),
    mountPoint: rootRouteRef,
  }),
);
